import React from "react";
import CuentaAhorros from "../../imagenes/ahorro.png";
import apiVisitas from "../../services/visitasApi";

class CuentaAhorrosPage extends React.Component {

    agregarVisitaCuentaAhorros = async () => {
        try {
            await apiVisitas.visitas.create({ tipo: "Cuenta de Ahorro" });
        } catch (error) {
            console.log("error al crear visíta " + error);
        }
    }

    componentDidMount() {
        this.agregarVisitaCuentaAhorros();
    }

    render() {
        return (
            <React.Fragment>
                <section id="hero" className="d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                                <h1>Cuenta de Ahorros</h1>
                                <h2 className="mb-3 texto-justificado">Apertura tu cuenta de ahorros y forma parte de nuestra familia.</h2>
                                <h2 className="texto-justificado">
                                    Te ofrecemos la posibilidad de administrar tu dinero de forma segura generando intereses a tu favor, esta cuenta te permite efectuar transferencias y retiros de tu dinero desde nuestras agencias o en cualquier cajero automático.
                                </h2>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 hero-img text-center" data-aos="zoom-in" data-aos-delay="200">
                                <img src={CuentaAhorros} className="image-cuenta-ahorros rounded " alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <main id="main">
                    <section id="services" className="services section-bg">
                        <br /> <br />
                        <div className="container" data-aos="fade-up">
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div class="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Beneficios
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                       - Manejas tu cuenta desde cualquier lugar con nuestra aplicación móvil.
                                    </h5>
                                    <h5 className="ps-4">
                                       - Accedes al fondo mortuorio que incluye los servicios de peluquería y odontología por solo $10 al año.
                                    </h5>
                                    <h5 className="ps-4">
                                       - Participas en los Sorteos Trimestrales de un Smart-TV.
                                    </h5>
                                    <h5 className="ps-4">
                                       - Recibes obsequios con tus transacciones.
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div class="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Monto de apertura
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                        El monto para la apertura de la cuenta de ahorros a la vista es de USD $35.00
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div class="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Tasa de Interés
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                        Obtienes la mejor rentabilidad con las tasas de interés más altas del mercado
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div class="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Requisitos para apertura de cuenta
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                       - Copia a color de cédula y papeleta de votación.
                                    </h5>
 
                                    <h5 className="ps-4">
                                       - Copia de la última planilla de cualquier servicio básico
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br />

                        </div>
                        <br />
                    </section>

                </main>
            </React.Fragment>
        )
    }
}

export default CuentaAhorrosPage;