import React from "react";
import FondoMortuorioiMG from "../../imagenes/fondomortuorio.jpg";
import apiVisitas from "../../services/visitasApi";

class FondoMortuorio extends React.Component {

    agregarVisitaFondoMortuorio = async () => {
        try {
            await apiVisitas.visitas.create({ tipo: "Fondo Mortuorio" });
        } catch (error) {
            console.log("error al crear visíta " + error);
        }
    }

    componentDidMount() {
        this.agregarVisitaFondoMortuorio();
    }

    render() {
        return (
            <React.Fragment>
                <section id="hero" className="d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                                <h1>Fondo Mortuorio</h1>
                                <h2>Todos los Socios que mantiene la cuenta con el movimiento tienen el beneficio de un fondo mortuorio.</h2>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 hero-img text-center" data-aos="zoom-in" data-aos-delay="200">
                                <img src={FondoMortuorioiMG} className="img-fluid  rounded w-50" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <main id="main">
                    <section id="services" className="services section-bg">
                        <br /> <br />
                        <div className="container" data-aos="fade-up">
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Requisitos:
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                        - Partida de defunción original.
                                    </h5>
                                    <h5 className="ps-4">
                                        - Copia de partida de nacimiento o copia de cédula.
                                    </h5>
                                    <h5 className="ps-4">
                                        - Certificado de ser socio de la cooperativa o copia de la libreta de ahorro.
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br />

                        </div>
                        <br />
                    </section>
                </main>
            </React.Fragment>
        )
    }
}

export default FondoMortuorio;