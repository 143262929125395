import React from "react";
import AhorroJuniorImg from "../../imagenes/ahorrojr.png";
import apiVisitas from "../../services/visitasApi";

class AhorroJunior extends React.Component {

    agregarVisitaAhorroJunior = async () => {
        try {
            await apiVisitas.visitas.create({ tipo: "Ahorro Junior" });
        } catch (error) {
            console.log("error al crear visíta " + error);
        }
    }

    componentDidMount() {
        this.agregarVisitaAhorroJunior();
    }

    render() {
        return (
            <React.Fragment>
                <section id="hero" className="d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                                <h1>Ahorro Junior</h1>
                                <h2>
                                    Fomenta la cultura del ahorro con los mas pequeños del hogar, esta cuenta está destinada a niños, niñas y adolescentes de hasta 16 años.
                                </h2>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 hero-img text-center" data-aos="zoom-in" data-aos-delay="200">
                                <img src={AhorroJuniorImg} className="image-cuenta-ahorros-junior rounded " alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <main id="main">
                    <section id="services" className="services section-bg">
                        <br /> <br />
                        <div className="container" data-aos="fade-up">
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Monto de apertura
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                        El monto para la apertura de la cuenta es de USD $5.00
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Tasa de Interés
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                        Las tasas de interés más altas del mercado
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Requisitos para apertura de cuenta
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                        - Copia a color de cédula y papeleta de votación del representante y del niño
                                    </h5>
                                    <h5 className="ps-4">
                                        - Copia de la última planilla de cualquier servicio básico
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Beneficios
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                        Participa en sorteos trimestrales
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br />
                        </div>
                        <br />
                    </section>

                </main>
            </React.Fragment>
        )
    }
}

export default AhorroJunior;