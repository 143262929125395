import React from "react";
import { Link } from "react-router-dom";
import AhorroInversion from './../../imagenes/ahorroinversion.jpeg';
import AhorroJunior from './../../imagenes/ahorrojunior.jpeg';
import AhorroProgramado from './../../imagenes/ahorroprogrmado.jpeg';


class Ahorroproductos extends React.Component {

    render() {
        return (
            <React.Fragment>
                <section id="services" className="services section-bg">
                    <div className="container" data-aos="fade-up">
                        <div className="section-title">
                            <h2>
                                Ahorra y Piensa en tu Futuro
                            </h2>
                            <p>Comienza a ahorrar tu dinero de forma segura con nosotros. Te invitamos a que descubras los productos que tenemos para ti según tus necesidades de ahorro:</p>
                        </div>

                        <div className="row">
                            <div className="col-xl-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                <div className="icon-box">
                                    <div className="icon">
                                        <img src={AhorroInversion} className="w-50 rounded" />
                                    </div>
                                    <h4>
                                        <Link to="/ahorro-inversion">
                                            Ahorro Inversión
                                        </Link>
                                    </h4>
                                    <p className="texto-justificado">Te ofrecemos tasas de interés competitivas con pagos mensuales de interés o al vencimiento.</p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
                                <div className="icon-box">
                                    <div className="icon">
                                        <img src={AhorroJunior} className="w-50 rounded" />
                                    </div>
                                    <h4>
                                        <Link to="/ahorro-junior" >
                                            Ahorro Junior
                                        </Link>
                                    </h4>
                                    <p className="texto-justificado">Es una cuenta de ahorro a la vista para las personas menores de 16 años, es un producto que tiene como propósito fomentar la cultura del ahorro</p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="400">
                                <div className="icon-box">
                                    <div className="icon">
                                        <img src={AhorroProgramado} className="w-50 rounded" />
                                    </div>
                                    <h4>
                                        <Link to="/ahorro-programado" className="link-menu" >
                                            Ahorro Programado
                                        </Link>
                                    </h4>
                                    <p className="texto-justificado">Estos valores ahorrados mensualmente permanecerán bloqueados en su cuenta de ahorros hasta el final del plazo fijado.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default Ahorroproductos;