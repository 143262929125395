import React from "react";
import creditoecofuturoDesktop from './../../imagenes/slider/credito-eco-futuro-desktop.jpg';
import creditoecofuturoMobile from './../../imagenes/slider/credito-eco-futuro-mobile.jpg';
import educacionFinancieraDesktop from './../../imagenes/slider/educacion-financiera-desktop.jpg';
import educacionFinancieraMobile from './../../imagenes/slider/educacion-financiera-mobile.jpg';
import medicinaGeneralDesktop from './../../imagenes/slider/medicinageneral-desktopl.jpg';
import medicinaGeneralMobile from './../../imagenes/slider/medicinageneral-mobile.jpg';
import creditoenlineaDesktop from './../../imagenes/slider/creditoenlinea-desktop.jpg';
import creditoenlineaMobile from './../../imagenes/slider/creditoenlinea-mobile.jpg';
import invierteutilidadesDesktop from './../../imagenes/slider/invierte-utilidades-desktop.jpg';
import invierteutilidadesMobile from './../../imagenes/slider/invierte-utilidades-mobile.png';
import facilitoDesktop from './../../imagenes/slider/facilito-desktop.png';
import { Link } from "react-router-dom";

class Slider extends React.Component {

    imagenIsMobile = () => {
    }

    render() {
        return (
            <React.Fragment>
                <section id="sliderc" className="p-0">
                    <div id="carouselExampleControls" className="carousel slide pt-0 mt-0" data-bs-ride="carousel">
                        <div className="carousel-inner ">
                        <div className="carousel-item active">
                                <a href="https://pagos.facilito.com.ec/aplicacion/futurolamanense" target="_blank">
                                    <img src={facilitoDesktop} className="w-100 image-slider-pc" />
                                    <img src={invierteutilidadesMobile} className="w-100 image-slider-mobile" />
                                </a>
                            </div>
                            <div className="carousel-item">
                                <Link to='/deposito-plazo-fijo'>
                                    <img src={invierteutilidadesDesktop} className="w-100 image-slider-pc" />
                                    <img src={invierteutilidadesMobile} className="w-100 image-slider-mobile" />
                                </Link>
                            </div>
                            <div className="carousel-item ">
                                <a href="https://solicitudcredito.futurolamanense.fin.ec/" target="_blank">
                                    <img src={creditoenlineaDesktop} className="w-100 image-slider-pc" />
                                    <img src={creditoenlineaMobile} className="w-100 image-slider-mobile" />
                                </a>
                            </div>
                            <div className="carousel-item">
                                <Link to='/medicina-general'>
                                    <img src={medicinaGeneralDesktop} className="w-100 image-slider-pc" />
                                    <img src={medicinaGeneralMobile} className="w-100 image-slider-mobile" />
                                </Link>
                            </div>
                            {
                                /*
     

                            <div className="carousel-item">
                                <Link to='/credito-violeta'>
                                    <img src={creditovioletaDesktop} className="w-100 image-slider-pc" />
                                    <img src={creditovioletaMobile} className="w-100 image-slider-mobile" />
                                </Link>
                            </div>
                                */
                            }

                            <div className="carousel-item ">
                                <Link to='/eco-futuro'>
                                    <img src={creditoecofuturoDesktop} className="w-100 image-slider-pc" />
                                    <img src={creditoecofuturoMobile} className="w-100 image-slider-mobile" />
                                </Link>
                            </div>
                            <div className="carousel-item ">
                                <Link to='/educacion-financiera'>
                                    <img src={educacionFinancieraDesktop} className="w-100 image-slider-pc" />
                                    <img src={educacionFinancieraMobile} className="w-100 image-slider-mobile" />
                                </Link>
                            </div>
                            {
                                /*
                                <div className="carousel-item ">
                                    <Link to='/futu-navideno'>
                                        <img src={creditofutunavidenoDesktop} className="w-100 image-slider-pc" />
                                        <img src={creditofutunavidenoMobile} className="w-100 image-slider-mobile" />
                                    </Link>
                                </div>
                                */
                            }

                        </div>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default Slider;