import React from "react";
import { ToastContainer } from "react-toastify";
import CreditoConsumoImg from "../../imagenes/consumoim.png";
import apiVisitas from "../../services/visitasApi";
import SimuladorCredito from "./simulador-credito";

class CreditoConsumo extends React.Component {

    agregarVisitaCreditoConsumo = async () => {
        try {
            await apiVisitas.visitas.create({ tipo: "Crédito de Consumo" });
        } catch (error) {
            console.log("error al crear visíta " + error);
        }
    }

    componentDidMount() {
        //this.agregarVisitaCreditoConsumo();
    }


    render() {
        return (
            <React.Fragment>
                <ToastContainer />
                <section id="hero" className="d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                                <h1>Crédito de Consumo</h1>
                                <h2 className="mb-3">
                                    La opción ideal para los que saben qué quieren, pero no cómo financiarlo.
                                </h2>
                                <h2 className="mb-3">
                                    Hasta $5.000 sin garante. Desde 12 a 36 meses plazo
                                </h2>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 hero-img text-center" data-aos="zoom-in" data-aos-delay="200">
                                <img src={CreditoConsumoImg} className="image-credito-consumo  rounded " alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <main id="main">
                    <section id="services" className="services section-bg">
                        <br /> <br />
                        <div className="container" data-aos="fade-up">
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <h5>
                                        Un crédito destinado a cumplir sus sueños y anhelos, financiamos la compra de bienes y pago de servicios, para hacer realidad todos sus proyectos, con la tasa más baja del mercado.
                                    </h5>
                                    <br />
                                    <div className="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Requisitos:
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                        - Cédula de identidad y papeleta de votación.
                                    </h5>
                                    <h5 className="ps-4">
                                        - Edad de 18 – 80 años.
                                    </h5>
                                    <h5 className="ps-4">
                                        - Roles de pago mecanizado o IESS
                                    </h5>
                                    <h5 className="ps-4">
                                        - Planilla de servicio básico.
                                    </h5>
                                    <h5 className="ps-4">
                                        - Impuesto predial (en caso de tenerlo).
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Tasa de Interés
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                        - Tasa de interés del 14 %
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Características del crédito:
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                        - AGILIDAD: Tu crédito se aprueba en un plazo de  24 a 48 horas.
                                    </h5>
                                    <h5 className="ps-4">
                                        - FLEXIBILIDAD: Este crédito se puede adaptar de acuerdo a tus necesidades.
                                    </h5>
                                    <h5 className="ps-4">
                                        - BENEFICIOS: Seguro médico para el deudor, cónyuge y 3 dependientes menores de 23 años, acceso a nuestros servicios de odontología, peluquería y un fondo mortuorio de hasta $5.000.
                                    </h5>
                                    <h5 className="ps-4">
                                        - FACILIDAD: Puedes cancelar las cuotas de tu crédito desde cualquier Agencia de la Coac. Futuro Lamanense o transferencias electrónicas.
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Beneficios
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                        Si pagas tus cuotas puntuales, participas en los sorteos trimestrales
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br />


                            <SimuladorCredito codigotipocredito={'014'} tipocredito={'consumo'}  />


                            <br />

                        </div>
                        <br />
                    </section>

                </main>
            </React.Fragment>
        )
    }
}

export default CreditoConsumo;