import React from "react";
import { Link } from "react-router-dom";
import Odontologia from './../../imagenes/odontologia.jpg';
import Peluqueria from './../../imagenes/peluqueria.png';
import FondoMortuorio from './../../imagenes/fondomortuorio.jpg';


class Beneficios extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section id="services" className="services ">
                    <div className="container" data-aos="fade-up">
                        <div className="section-title">
                            <h2>Beneficios</h2>
                            <p>Por ser socio activo de tu cooperativa de ahorro y crédito Futuro Lamanense, tienes acceso a los siguientes servicios:</p>
                        </div>

                        <div className="row">
                            <div className="col-xl-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                <div className="icon-box">
                                    <div className="icon">
                                        <img src={Peluqueria} className="w-50 rounded" />
                                    </div>
                                    <h4>
                                        <Link to="/peluqueria" >
                                            Peluquería
                                        </Link>
                                    </h4>
                                    <p className="texto-justificado">Pensando en el beneficio y comodidad de nuestros socios/as, ponemos a su disposición el servicio de PELUQUERÍA especializada para ambos sexos.</p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
                                <div className="icon-box">
                                    <div className="icon">
                                        <img src={Odontologia} className="w-50 rounded" />
                                    </div>
                                    <h4>
                                        <Link to="/odontologia" >
                                            Odontología
                                        </Link>
                                    </h4>
                                    <p className="texto-justificado">Pensando en el beneficio y comodidad de nuestros socios/as, ponemos a su disposición el servicio de ODONTOLOGÍA.</p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="400">
                                <div className="icon-box">
                                    <div className="icon">
                                        <img src={FondoMortuorio} className="w-50 rounded" />
                                    </div>
                                    <h4>
                                        <Link to="/fondo-mortuorio" >
                                            Fondo Mortuorio
                                        </Link>
                                    </h4>
                                    <p className="texto-justificado">Servicios de Fondo Mortuorio para nuestros socios. Incluye el servicio de peluquería y odontología.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default Beneficios;