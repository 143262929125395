import React from "react";
import AhorroProgramadoImg from "../../imagenes/programadoahorro.png";
import apiVisitas from "../../services/visitasApi";

class AhorroProgramado extends React.Component {

    agregarVisitaAhorroProgramado = async () => {
        try {
            await apiVisitas.visitas.create({ tipo: "Ahorro Programado" });
        } catch (error) {
            console.log("error al crear visíta " + error);
        }
    }

    componentDidMount() {
        this.agregarVisitaAhorroProgramado();
    }

    render() {
        return (
            <React.Fragment>
                <section id="hero" className="d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                                <h1>Ahorro Programado</h1>
                                <h2 className="mb-3">
                                    Esta cuenta se crea a partir de una cuenta activa, con un monto destinado para ahorro.
                                </h2>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 hero-img text-center" data-aos="zoom-in" data-aos-delay="200">
                                <img src={AhorroProgramadoImg} className="image-cuenta-ahorros-programado rounded " alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <main id="main">
                    <section id="services" className="services section-bg">
                        <br /> <br />
                        <div className="container" data-aos="fade-up">
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Que ofrece
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                        Mayor rentabilidad, con la mejor tasa de interés del mercado, producimos tu dinero de forma rápida, segura y confiable.
                                        Invierte para un futuro mejor.
                                    </h5>
                                    <br />
                                </div>
                                <div className="col-xl-12 col-md-6 align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Beneficios
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                        Participa en los sorteos trimestrales
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br />
                        </div>
                        <br />
                    </section>

                </main>
            </React.Fragment>
        )
    }
}

export default AhorroProgramado;