import React from "react";
import logoVertical from "../../imagenes/logoblancas.png";

class GobiernoCorporativo extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section id="hero" className="d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 d-flex flex-column  pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                                <div className="text-center">
                                    <h1>GOBIERNO CORPORATIVO</h1>
                                </div>

                                <div className="text-center pt-0">
                                    <img src={logoVertical} className="logovertical-gerencia" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <main id="main">
                    <section id="services" className="services section-bg">
                        <br /> <br /> <br />
                        <div className="container" data-aos="fade-up">
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <h5 className="texto-justificado">
                                        Es el sistema por el cual una organización toma e implementa decisiones para lograr sus objetivos. Consiste en la definición de la estructura y funcionamiento de la administración de la organización para, definir derechos y deberes exigibles a los administradores y establecer los mecanismos de protección de los derechos de los socios.
                                    </h5>
                                    <br />
                                    <h5 className="texto-justificado">
                                        Nuestra estructura de Gobierno Corporativo está diseñada acorde a lo que dispone La Ley de Economía Popular y Solidaria, lo que permite articular planes estratégicos, políticas corporativas y mecanismos de control y transparencia bien estructurados, entre todas las instancias de la Cooperativa.
                                    </h5>
                                    <br />
                                    <h5 className="texto-justificado">
                                        La buena gestión de Gobierno Corporativo nos ha permitido obtener certificaciones que reconocen la mejora en la calidad de gobernanza. Entre ellas están: Certificación GIF Gobernanza e inclusión.
                                    </h5>
                                </div>
                            </div>
                            <br /> <br />

                            <div className="row">
                                <div className="col-xl-2 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100"></div>
                                <div className="col-xl-8 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <h5>DIRECTIVA</h5>
                                    <table className="table table-bordered">
                                        <thead >
                                            <tr className="table-primary">
                                                <th scope="col" className="text-center">APELLIDOS Y NOMBRES</th>
                                                <th scope="col" className="text-center">CARGO</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="text-center">CPA. Moran Marmolejo Fabian Medardo</td>
                                                <td className="text-center">Gerente</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">Lic. Jácome Calderon Alba Verónica</td>
                                                <td className="text-center">Presidente</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">Abg. Escobar Chango Guido Fabian</td>
                                                <td className="text-center">Vicepresidente</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">Abg. Jaramillo Medina Enrrique Orlando</td>
                                                <td className="text-center">Secretario</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <br /> <br />
                            <div className="row">
                                <div className="col-xl-2 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100"></div>
                                <div className="col-xl-8 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <h5>NÓMINA CONSEJO DE ADMINISTRACIÓN</h5>
                                    <table className="table table-bordered">
                                        <thead >
                                            <tr className="table-primary">
                                                <th scope="col" className="text-center">APELLIDOS Y NOMBRES</th>
                                                <th scope="col" className="text-center">CARGO</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="text-center">Jácome Calderon Alba Verónica</td>
                                                <td className="text-center">Vocal Principal 1</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">Escobar Chango Guido Fabian</td>
                                                <td className="text-center">Vocal Principal 2</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">Lcda. Palma Bravo Nelly Janeth</td>
                                                <td className="text-center">Vocal Principal 3</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">Ing. Delgado Rodríguez Hugo Bolivar</td>
                                                <td className="text-center">Vocal Principal 4</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">Lcda. Loor Valencia Ramona Yannyne</td>
                                                <td className="text-center">Vocal Principal 5</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">Dr. Guzman Simanca Jesus Manuel</td>
                                                <td className="text-center">Vocal Principal 6</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">Ing.Romero Calderon Michelle Virginia</td>
                                                <td className="text-center">Vocal Principal 7</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">Sr. Rosado Sosa Daniel Laureno</td>
                                                <td className="text-center">Vocal Principal 8</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">Sra. Armas Vela Elvia Juliana</td>
                                                <td className="text-center">Vocal Principal 9</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">Montes Macias Galo Aristobal</td>
                                                <td className="text-center">Vocal Suplente 1</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">Ayala Carriel Gaudencio Vincente</td>
                                                <td className="text-center">Vocal Suplente 2</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">Villegas Rodriguez Laura Elizabeth</td>
                                                <td className="text-center">Vocal Suplente 3</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">Chanaluisa Yanchapaxi Piedad de las Mercedes</td>
                                                <td className="text-center">Vocal Suplente 4</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">Navarrete Boharquez Adalaida Angela</td>
                                                <td className="text-center">Vocal Suplente 5</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">Zavala Marcillo Mercedes Benita</td>
                                                <td className="text-center">Vocal Suplente 6</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">Saavedra Velez Carlos David</td>
                                                <td className="text-center">Vocal Suplente 7</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">Moreno Reyes Martha Jenny</td>
                                                <td className="text-center">Vocal Suplente 8</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <br /> <br />
                            <div className="row">
                                <div className="col-xl-2 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100"></div>
                                <div className="col-xl-8 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <h5>NÓMINA CONSEJO DE VIGILANCIA</h5>
                                    <table className="table table-bordered">
                                        <thead >
                                            <tr className="table-primary">
                                                <th scope="col" className="text-center">APELLIDOS Y NOMBRES</th>
                                                <th scope="col" className="text-center">CARGO</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="text-center">Palomo Caisa Beatriz Elsa</td>
                                                <td className="text-center">Vocal Principal 1</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">Cabrera Rayna Alex Rodolfo</td>
                                                <td className="text-center">Vocal Principal 2</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">Albarracin Cepeda Laura Fernanda</td>
                                                <td className="text-center">Vocal Principal 3</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">Torres Velez Erwin Santiago</td>
                                                <td className="text-center">Vocal Principal 4</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">Chango Guaman Carmen Piedad</td>
                                                <td className="text-center">Vocal Principal 5</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">Espinoza Conforme Jesus Vicente</td>
                                                <td className="text-center">Vocal Principal 6</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">Paz Maldonado Mery Elizabeth</td>
                                                <td className="text-center">Vocal Principal 7</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">Ruiz Mena Pedro Fabian</td>
                                                <td className="text-center">Vocal Principal 8</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">Cedeño Troya Dolores Magaly</td>
                                                <td className="text-center">Vocal Principal 9</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">Peñaherrara Tovar Rosario Guadalupe</td>
                                                <td className="text-center">Vocal Principal 10</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                        <br /> <br /> <br /> <br />
                    </section>

                    <section id="services" className="services ">
                        <br /> <br /> <br />
                        <div className="container" data-aos="fade-up">
                            <div className="section-title">
                                <h2>Comités por Normativa</h2>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-xl-6 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="icon-box">
                                        <h4><a>Comité De Administración Integral De Riesgos</a></h4>
                                        <h5 className="texto-justificado">
                                            Es un órgano asesor del Consejo de Administración, responsable del diseño de políticas, procesos, estrategias, metodologías, sistemas de información y procedimientos para la eficiente gestión integral de riesgos.
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="icon-box">
                                        <h4><a>Comité De Cumplimiento</a></h4>
                                        <h5 className="texto-justificado">
                                            Es el encargado de velar por la aplicación de las políticas y procedimientos de control para mitigar el riesgo de Lavado de Activos y Financiamiento de Delitos incluido el Terrorismo.
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-xl-6 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="icon-box">
                                        <h4><a>Comité De Conducta Y Ética</a></h4>
                                        <h5 className="texto-justificado">
                                            Es el encargado de garantizar que los objetivos del uso de tecnología estén alineados a los objetivos generales de la cooperativa, complementariamente debe velar la ejecución de los procesos para evitar el riesgo de retrasos o incumplimientos que no permitan alcanzar los objetivos institucionales
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="icon-box">
                                        <h4><a>Comité De Conducta Y Ética</a></h4>
                                        <h5 className="texto-justificado">
                                            Es el encargado de preparar y establecer procedimientos para evitar conflictos de interés, aplicar las sanciones ante los incumplimientos de principios y deberes, a fin de promover el comportamiento de excelencia profesional y personal dentro de la cooperativa.
                                        </h5>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <br /> <br /> <br />
                    </section>

                    <section id="services" className="services section-bg">
                        <br /> <br /> <br />
                        <div className="container" data-aos="fade-up">
                            <div className="section-title">
                                <h2>Comités creados por la Cooperativa</h2>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-xl-6 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="icon-box">
                                        <h4><a>Comité De Gobernanza</a></h4>
                                        <h5 className="texto-justificado">
                                            Es el encargado de realizar la planeación, gestión, ejecución y monitoreo de la gobernanza en la cooperativa, así como también adoptar las acciones necesarias para la gobernanza efectiva de la entidad, por lo que recomienda al Consejo de Administración las estrategias para la mejora de las prácticas de un buen gobierno corporativo.
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="icon-box">
                                        <h4><a>Comité De Cumplimiento</a></h4>
                                        <h5 className="texto-justificado">
                                            Son aquellos que están conformados de acuerdo a los requerimientos de control interno propios de la cooperativa, así como también aquellos que están establecidos por disposiciones normativas, siendo los siguientes:
                                        </h5>
                                        <h5>
                                            • Comisión de Crédito
                                        </h5>
                                        <h5>
                                            • Comisión de Educación y Cultura
                                        </h5>
                                        <h5>
                                            • Comisión de Asuntos Sociales y Deporte
                                        </h5>
                                        <h5>
                                            • Comisión de Licitación y Adquisiciones
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br /> <br /> <br />
                    </section>
                </main>
            </React.Fragment>
        )
    }
}

export default GobiernoCorporativo;