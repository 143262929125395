import React from "react";
import CreditoViciendaImg from "../../imagenes/credcasa.png";
import apiVisitas from "../../services/visitasApi";
import SimuladorCredito from "./simulador-credito";

class CreditoInmobiliario extends React.Component {

    agregarVisitaCreditoInmobiliario = async () => {
        try {
            await apiVisitas.visitas.create({ tipo: "Crédito Inmobiliario" });
        } catch (error) {
            console.log("error al crear visíta " + error);
        }
    }

    componentDidMount() {
        this.agregarVisitaCreditoInmobiliario();
    }

    render() {
        return (
            <React.Fragment>
                <section id="hero" className="d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                                <h1>Crédito de vivienda con llave en mano.</h1>
                                <h2 className="mb-3">
                                    Haz realidad el sueño de tener tu casa propia, ofrecemos el financiamiento que necesitas con las tasas de interés más bajas de mercado.
                                </h2>
                                <h2>
                                    Hasta $70.000, desde 12 a 180 meses plazo.
                                </h2>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 hero-img text-center" data-aos="zoom-in" data-aos-delay="200">
                                <img src={CreditoViciendaImg} className="image-credito-inmobiliario rounded " alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <main id="main">
                    <section id="services" className="services section-bg">
                        <br /> <br />
                        <div className="container" data-aos="fade-up">
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <h5>
                                        Ofrecemos la tasa más baja del mercado con excelente financiamiento, para que hagas realidad el sueño de tener tu casa propia.
                                    </h5>
                                    <br />
                                    <div className="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Requisitos:
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                        - Cédula de identidad y papeleta de votación.
                                    </h5>
                                    <h5 className="ps-4">
                                        - Mayor de 18 años.
                                    </h5>
                                    <h5 className="ps-4">
                                        - Justificación de ingresos (Roles de pago, RUC o RIMPE, declaraciones o facturas, certificado comercial).
                                    </h5>
                                    <h5 className="ps-4">
                                        - Planilla de servicio básico.
                                    </h5>
                                    <h5 className="ps-4">
                                        - Libre de gravamen
                                    </h5>
                                    <h5 className="ps-4">
                                        - Original del pago del impuesto predial.
                                    </h5>
                                    <h5 className="ps-4">
                                        - Escritura Original.
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Características del crédito:
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                        - AGILIDAD: Tu crédito se aprueba en un plazo de  24 a 48 horas.
                                    </h5>
                                    <h5 className="ps-4">
                                        - FLEXIBILIDAD: Pago justo de acuerdo con la tasa de interés aplicada.
                                    </h5>
                                    <h5 className="ps-4">
                                        - BENEFICIOS: Seguro médico para el deudor, cónyuge y 3 dependientes menos de 23 años, seguro de gravamen, también puedes acceder a nuestros servicios de odontología, peluquería y un fondo mortuorio de hasta $5.000.
                                    </h5>
                                    <h5 className="ps-4">
                                        - FACILIDAD: Puedes cancelar las cuotas de tu crédito desde cualquier Agencia de la Coac. Futuro Lamanense o transferencias electrónicas.
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Beneficios
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                        Si pagas tus cuotas puntuales, participas en los sorteos trimestrales
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br/>
                            <SimuladorCredito codigotipocredito={'010'} tipocredito={'inmobiliario'}  />
                            <br />
                        </div>
                        <br />
                    </section>

                </main>
            </React.Fragment>
        )
    }
}

export default CreditoInmobiliario;