import React from "react";
import logoVertical from "../../imagenes/logoblancas.png";
import coacResena from "../../imagenes/coacresena.jpeg"

class ResenaHistorica extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section id="hero" className="d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 d-flex flex-column  pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                                <div className="text-center">
                                    <h1>¿RESEÑA HISTÓRICA?</h1>
                                </div>

                                <div className="text-center pt-0">
                                    <img src={logoVertical} className="logovertical" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <main id="main">
                    <section id="services" className="services section-bg">
                        <br /> <br /> <br /> <br />
                        <div className="container" data-aos="fade-up">
                            <div className="section-title">
                                <h2>Cooperativa de Ahorro y Crédito Futuro Lamanense</h2>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-xl-6 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <img src={coacResena} className="w-100 rounded" />
                                </div>

                                <div className="col-xl-6 col-md-6 align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
                                    <p className="texto-justificado texto-grande">
                                        La cooperativa de ahorro y crédito nace en 1996 de una iniciativa surgida por la necesidad de promover el ahorro, esta idea nace de parte del sacerdote Holger Hidalgo Párroco de ese entonces de La Maná, quien al darse cuenta que la gente pobre de las diferentes comunidades tenían dinero para su subsistencia, pero al mismo tiempo gastaban demasiado en vanidades y no ahorraban, promovió y formo una pequeña caja solidaria en la que las personas que tenían confianza en este sacerdote comenzaron a ahorrar, como el ahorro se iba fomentando, comienza a extender pequeño créditos destinados a la crianza de pollos, cerdos en proporciones pequeñas (pollos 5 a 10, chanchos 1).
                                    </p>

                                    <p className="texto-justificado texto-grande">
                                        A partir de ahí y al ver la necesidad de este conglomerado, llega a la Mana, a visitar a esta caja comunitaria una institución que financiaban créditos para ser entregados a pequeños agricultores y personas con escasos recursos económicos y con deseos de superación, esta institución es el CAAP (Centro Andino de Acción Popular), por amistad y confianza en el sacerdote quien representaba a esta caja solidaria, el Dr. Miguel Gaibor Representante del CAAP presenta la propuesta de un préstamo para esta caja comunitaria. En el año 1997 esta propuesta por el CAAP es aceptada gustosa por el sacerdote, y es así que tan solo con la confianza y amistad con el sacerdote Holger Hidalgo otorgan un crédito de 210 millones de sucres, y desde aquí comienza a funcionar como una caja de ahorro solidaria, sus primeros pagares son a favor del CAAP por que la Caja de Ahorro solidaria no tenía vida jurídica. Estos fondos fueron entregados en préstamo a pequeños agricultores, consistían en 6 millones de sucres, con proyectos de reforestación, crianza de cerdos, gallinas, siembra de fréjol, etc.
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <p className="texto-justificado texto-grande">
                                        En este mismo año se empieza a gestionar la vida jurídica de la caja de ahorro comunitaria, convirtiéndose en Cooperativa de Ahorro y Crédito y es así como obtiene su primer nombre Cooperativa de Ahorro y crédito 16 de Julio, pero la Dirección de cooperativas pide que cambien el nombre por existir ya una institución con ese mismo nombre, luego toma el nombre de Cooperativa de Ahorro y Crédito Futuro Lamanense obteniendo su vida jurídica legalmente constituida y según Acuerdo Ministerial No.0152, del 3 de Marzo de 1998 e inscrita en el Registro General de Cooperativas con el No. de Orden no. 006084 de 3 de marzo de 1998 con su primer gerente el Sacerdote Holger Hidalgo.
                                    </p>
                                    
                                </div>
                            </div>
                        </div>
                        <br /> <br /> <br /> <br />
                    </section>

                </main>
            </React.Fragment>
        )
    }
}

export default ResenaHistorica;