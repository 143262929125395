import React from "react";
import { Link } from "react-router-dom";

/** */
class CreditoInversiones extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section id="services" className="services section-bg">
                    <div className="container" data-aos="fade-up">
                        <div className="section-title">
                            <h2>NUESTROS Créditos</h2>
                        </div>

                        <div className="row">
                            <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                <div className="icon-box">
                                    <div className="icon"></div>
                                    <h4>
                                        <Link to="/credito-consumo"  >
                                            Crédito de consumo
                                        </Link>
                                    </h4>
                                    <p>Un crédito destinado a cumplir tus sueños y anhelos, financiamos la compra de bienes y pago de servicios, para que hagas realidad todos tus proyectos.</p>
                                    <br /> <br />
                                    <Link to="/credito-consumo" className="mt-3" >
                                        Más información
                                    </Link>

                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
                                <div className="icon-box">
                                    <div className="icon"></div>
                                    <h4>
                                        <Link to="/credito-inmobiliario" >
                                            Crédito Inmobiliario
                                        </Link>
                                    </h4>
                                    <p className="mb-4">Es hora de hacer realidad tu sueño de tener vivienda. Te apoyamos en hacer realidad tu sueño con excelentes financiamientos</p>
                                    <br /> <br />
                                    <Link to="/credito-inmobiliario" className="mt-4">
                                        Más información
                                    </Link>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
                                <div className="icon-box">
                                    <div className="icon"></div>
                                    <h4>
                                        <Link to="/microcredito" >
                                            Microcrédito
                                        </Link>
                                    </h4>
                                    <p>Queremos verte crecer, por eso te ofrecemos el mejor crédito para tu negocio, accede hasta $60.000, a 72 meses plazo, con frecuencia de pago mensual o semestral.</p>
                                    <br /> <br />
                                    <Link to="/microcredito" className="mt-4">
                                        Más información
                                    </Link>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="400">
                                <div className="icon-box">
                                    <div className="icon"></div>
                                    <h4>
                                        <Link to="/emprende-futuro" >
                                            Crédito Violeta
                                        </Link>
                                    </h4>
                                    <p>Un crédito destinado a mujeres emprendedoras y visionarias con ganas de cumplir sus sueños y anhelos, con la tasa más baja del mercado</p>
                                    <br /> <br />
                                    <Link to="/emprende-futuro" className="mt-3">
                                        Más información
                                    </Link>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>

            </React.Fragment>
        )
    }
}

export default CreditoInversiones;