import './App.css';
import Index from "./pages/index";
import QuienesSomos from './pages/nosotros/quienes-somos';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import React from 'react';

import Navbar from './pages/components/navbar';
import Footer from './pages/components/footer';
import ResenaHistorica from './pages/nosotros/resena-historica';
import Cosede from './pages/nosotros/cosede';
import InformacionFinanciera from './pages/nosotros/informacion-financiera';
import Gerencia from './pages/nosotros/gerencia';
import Agencias from './pages/nosotros/Agencias';
import GobiernoCorporativo from './pages/nosotros/gobierno-coporativo';
import CodigoEtica from './pages/nosotros/codigo-etica';
import CreditoConsumo from './pages/creditos/credito-consumo';
import CreditoInmobiliario from './pages/creditos/credito-inmobiliario';
import Microcredito from './pages/creditos/microcredito';
import EmprendeFuturo from './pages/creditos/emprende-futuro';
import CuentaAhorrosPage from './pages/ahorros/cuentas-ahorros';
import AhorroInversion from './pages/ahorros/ahorro-inversion';
import AhorroJunior from './pages/ahorros/ahorro-junior';
import AhorroProgramado from './pages/ahorros/ahorro-programado';
import DepositoPlazoFijo from './pages/inversiones/deposito-plazo-fijo';
import Peluqueria from './pages/beneficios/peluqueria';
import Odontologia from './pages/beneficios/odontologia';
import FondoMortuorio from './pages/beneficios/fondo-mortuorio';
import Horarios from './pages/enlinea/horarios';
import Contactos from './pages/enlinea/contacto';
import EducacionFinanciera from './pages/educacion-financiera';
import RecomiendayGana from './pages/enlinea/recomiendaygana';
import Indicadoresgenero from './pages/nosotros/Indicadoresgeneros';
import LeyProteccionDatosEcuador from './pages/blog/ley-proteccion-datos-ecuador';
import CreditoVioleta from './pages/creditos/credito-violeta/credito-violeta';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import EcoFuturo from './pages/creditos/credito-eco-futuro/ecofuturo';
import FutuNavideno from './pages/creditos/credito-futu-navideno/futu-navideno';
import CursosVacacionales from './pages/beneficios/cursos-vacacionales';
import CreditoFutuAniversario from './pages/creditos/credito-futuaniversario/futuaniversario';
import MedicinaGeneral from './pages/beneficios/medicina-general';
import QuejasYReclamos from './pages/enlinea/quejasyreclamos';
import SeguimientoQuejasYReclamos from './pages/enlinea/seguimientoquejasreclamos';
import Noticia from './pages/nosotros/noticias/Noticia';
import NoticiaDetalle from './pages/nosotros/noticias/NoticiaDetalle';

function App() {
  return (
    <BrowserRouter forceRefresh={true}>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Index} />
        <Route exact path="/index" component={Index} />
        <Route exact path="/noticias" component={Noticia} />
        <Route exact path="/noticias/:categName/:categId/:noticeId" component={NoticiaDetalle} />
        <Route exact path="/quienes-somos" component={QuienesSomos} />
        <Route exact path="/resena-historica" component={ResenaHistorica} />
        <Route exact path="/cosede" component={Cosede} />
        <Route exact path="/informacion-financiera" component={InformacionFinanciera} />
        <Route exact path="/gerencia" component={Gerencia} />
        <Route exact path="/agencias" component={Agencias} />
        <Route exact path="/gobierno-corporativo" component={GobiernoCorporativo} />
        <Route exact path="/indicadores-genero" component={Indicadoresgenero} />
        <Route exact path="/codigo-etica" component={CodigoEtica} />

        {/**  Ahorros */}
        <Route exact path="/cuenta-ahorros" component={CuentaAhorrosPage} />
        <Route exact path="/ahorro-inversion" component={AhorroInversion} />
        <Route exact path="/ahorro-junior" component={AhorroJunior} />
        <Route exact path="/ahorro-programado" component={AhorroProgramado} />

        {/**  Créditos */}
        <Route exact path="/credito-consumo" component={CreditoConsumo} />
        <Route exact path="/credito-inmobiliario" component={CreditoInmobiliario} />
        <Route exact path="/microcredito" component={Microcredito} />
        <Route exact path="/emprende-futuro" component={EmprendeFuturo} />
        <Route exact path="/credito-violeta" component={CreditoVioleta} />
        <Route exact path="/eco-futuro" component={EcoFuturo} />
        <Route exact path="/futu-navideno" component={FutuNavideno} />
        <Route exact path="/futu-aniversario" component={CreditoFutuAniversario} />

        {/** Beneficios */}
        <Route exact path="/peluqueria" component={Peluqueria} />
        <Route exact path="/odontologia" component={Odontologia} />
        <Route exact path="/medicina-general" component={MedicinaGeneral} />
        <Route exact path="/fondo-mortuorio" component={FondoMortuorio} />
        <Route exact path="/cursos-vacacionales" component={CursosVacacionales} />

        {/** Plazofijo */}
        <Route exact path="/deposito-plazo-fijo" component={DepositoPlazoFijo} />

        <Route exact path="/horarios" component={Horarios} />
        <Route exact path="/contactos" component={Contactos} />
        <Route exact path="/educacion-financiera" component={EducacionFinanciera} />
        <Route exact path="/recomienda-y-gana" component={RecomiendayGana} />
        <Route exact path="/ley-proteccion-datos" component={LeyProteccionDatosEcuador} />
        <Route exact path="/quejas-reclamos-sugerencias" component={QuejasYReclamos} />
        <Route exact path="/seguimiento-quejas-reclamos-sugerencias" component={SeguimientoQuejasYReclamos} />
      </Switch>
      <Footer />
      {
        /**
         <GoogleReCaptchaProvider reCaptchaKey="6LdOXxApAAAAACZpjBgggvzLYoYJzj4_a4AXI9Lb" useRecaptchaNet="true"
          useEnterprise="true">
          <GoogleReCaptcha />
        </GoogleReCaptchaProvider>
         */

      }

    </BrowserRouter>
  );
}

export default App;
