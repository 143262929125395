import React from "react";
import { ToastContainer, toast } from "react-toastify";
import QuejasImg from "../../imagenes/quejas.png";
import quejasyreclamosApi from "../../services/quejasyreclamosApi";
import { Link } from "react-router-dom";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Global from "./../../services/urlGlobal";

class SeguimientoQuejasYReclamos extends React.Component {

    state = {
        form: {
            identificacion: ''
        },
        formularios: [],
        open: false,
        'idformulario': 0,
        'nombres': '',
        'identificacion': '',
        'email': '',
        'tipo': '',
        'mensaje': '',
        'celular': '',
        'fechahora': '',
        'idprovincia': '',
        'idcuidad': '',
        'urlarchivo': '',
        'estado': '',
        'agencia': '',
    }

    onOpenModal = (nombres, mensaje, fechahora, agencia, urlarchivo, estado, email) => {
        this.setState({ open: true, nombres: nombres, mensaje: mensaje, fechahora: fechahora, agencia: agencia, urlarchivo: urlarchivo, estado: estado, email: email });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    consultaProcesos = async (e) => {
        e.preventDefault();
        try {
            let identificacion = this.state.form.identificacion;
            const data = await quejasyreclamosApi.api.consultaproceso(identificacion);
            this.setState({ formularios: data.response });
        } catch (error) {
            console.log(error);
        }
    }

    handleChange = (e) => {
        this.setState({
            form: {
                ... this.state.form,
                [e.target.name]: e.target.value,
            }
        })
    }

    existeArchivo = () => {
        if (this.state.urlarchivo != "") {
            return (
                <p>
                    <a target="_blank" href={Global.UrlGlobal.urlQuejasReclamosArchivos + this.state.urlarchivo}>Archivo adjunto</a>
                </p>
            );
        } else {
            return (
                <div></div>
            );
        }
    }

    render() {
        return (
            <React.Fragment>
                <ToastContainer />
                <section id="hero" className="d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                                <h1>Puedes dar seguimiento de tus quejas, reclamos y sugerencias</h1>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
                                <img src={QuejasImg} className="img-fluid animated rounded " alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <main id="main">
                    <section id="services" className="services section-bg">
                        <div className="container" data-aos="fade-up">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to='/'>Inicio</Link></li>
                                    <li className="breadcrumb-item"><Link to='/quejas-reclamos-sugerencias'>Quejas, Reclamos y Sugerencias</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Seguimiento </li>
                                </ol>
                            </nav>
                            <div className="alert alert-primary" role="alert">
                                <h5 className="texto-justificado mb-0">
                                    Consultar proceso
                                </h5>
                            </div>
                            <form onSubmit={this.consultaProcesos}>
                                <div className="row mt-3">
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label className="ps-1">Identificación</label>
                                            <input className="form-control" type="text" pattern="[0-9]+" onChange={this.handleChange} name="identificacion" value={this.state.form.identificacion} required />
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <button className="mt-3 btnn" type="submit">
                                            Consultar
                                        </button>
                                    </div>
                                </div>
                            </form>

                            <hr />
                            <div className="table-responsive">
                                <table className="table bg-white">
                                    <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Nombres</th>
                                            <th scope="col">Identificación</th>
                                            <th scope="col">Tipo</th>
                                            <th scope="col">Fecha</th>
                                            <th scope="col">Mensaje</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.formularios.map((data, index) => {
                                                return (
                                                    <tr key={index + 1}>
                                                        <td>{index + 1}</td>
                                                        <td>{data.nombres}</td>
                                                        <td>{data.identificacion}</td>
                                                        <td>{data.tipo}</td>
                                                        <td>{data.fechahora}</td>
                                                        <td>{data.mensaje}</td>
                                                        <td>
                                                            <button type="button" className="btn btn-outline-dark" onClick={() => { this.onOpenModal(data.nombres, data.mensaje, data.fechahora, data.agencia, data.urlarchivo, data.estado, data.email) }}>
                                                                Ver
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </section>
                </main>


                <Modal open={this.state.open} onClose={this.onCloseModal} center>
                    <h2>Detalle</h2>
                    <br />
                    <p> <b>Nombres: </b>
                        {this.state.nombres}
                    </p>
                    <p> <b>Fecha y hora: </b>
                        {this.state.fechahora}
                    </p>
                    <p> <b>Mensaje: </b>
                        {this.state.mensaje}
                    </p>
                    <p> <b>Agencia: </b>
                        {this.state.agencia}
                    </p>
                    <p> <b>Email: </b>
                        {this.state.email}
                    </p>
                    <p> <b>Estado: </b>
                        {this.state.estado}
                    </p>
                    {
                        this.existeArchivo()
                    }
                </Modal>
            </React.Fragment>
        )
    }
}

export default SeguimientoQuejasYReclamos;