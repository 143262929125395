import React from "react";
import infoFinanciera from "../../imagenes/infofinanciera.png";
import balanceGeneral from "../../imagenes/balancegeneral.png";
import estadoResultado from "../../imagenes/Estadoresultado.png";

class InformacionFinanciera extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section id="hero" className="d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                                <h1>INFORMACIÓN FINANCIERA</h1>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
                                <img src={infoFinanciera} className="img-fluid animated rounded" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <main id="main">
                    <section id="services" className="services section-bg">
                        <br /> <br /> <br /> <br />
                        <div className="container" data-aos="fade-up">
                            <div className="section-title">
                                <h2>Información Anual</h2>
                            </div>
                            <div className="row">
                                <div className="col-xl-6 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="icon-box">
                                        <div className="icon">
                                            <img src={balanceGeneral} className="w-25 rounded" />
                                        </div>
                                        <h4><a href="">Balance General</a></h4>
                                        <p className="texto-justificado">
                                            Cooperativa de ahorro y crédito Futuro Lamanense pone a su disposición los documentos que detallan información anual del balance general.
                                        </p>
                                        <br />
                                        <button className="btn btn-secondary w-100">
                                            Balance General 2021
                                        </button>
                                        <br />
                                        <button className="btn btn-secondary mt-3 w-100">
                                            Balance General 2022
                                        </button>
                                    </div>
                                </div>

                                <div className="col-xl-6 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
                                    <div className="icon-box">
                                        <div className="icon">
                                            <img src={estadoResultado} className="w-25 rounded" />
                                        </div>
                                        <h4><a href="">Estado de Resultados</a></h4>
                                        <p className="texto-justificado">
                                            Cooperativa de ahorro y crédito Futuro Lamanense pone a su disposición los documentos que detallan información anual del Estado de Resultado
                                        </p>
                                        <br />
                                        <button className="btn btn-secondary w-100">
                                            Estado de Resultados 2021
                                        </button>
                                        <br />
                                        <button className="btn btn-secondary mt-3 w-100">
                                            Estado de Resultados 2022
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br /> <br /> <br /> <br />
                    </section>

                </main>
            </React.Fragment>
        )
    }
}

export default InformacionFinanciera;