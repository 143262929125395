import React from "react";
import CreditoConsumoImg from "../../imagenes/microcredito.png";
import apiVisitas from "../../services/visitasApi";
import SimuladorCredito from "./simulador-credito";

class Microcredito extends React.Component {

    agregarVisitaMicrocredito = async () => {
        try {
            await apiVisitas.visitas.create({ tipo: "Microcrédito" });
        } catch (error) {
            console.log("error al crear visíta " + error);
        }
    }

    componentDidMount() {
        this.agregarVisitaMicrocredito();
    }

    render() {
        return (
            <React.Fragment>
                <section id="hero" className="d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                                <h1>MICROCRÉDITO</h1>
                                <h2 className="mb-3">
                                    Haz realidad todos tus proyectos, surte o reactiva tu negocio, confiamos en ti y queremos verte crecer, por ello ofrecemos nuestros microcréditos con pocos requisitos y con las tasas de interés mas bajas del mercado.
                                </h2>
                                <h2 className="mb-3">
                                    Hasta $80.000 con garante, desde 12 a 96 meses plazos
                                </h2>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 hero-img text-center" data-aos="zoom-in" data-aos-delay="200">
                                <img src={CreditoConsumoImg} className="image-credito-microcredito rounded " alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <main id="main">
                    <section id="services" className="services section-bg">
                        <br /> <br />
                        <div className="container" data-aos="fade-up">
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <h5>
                                        Queremos verte crecer, por eso te ofrecemos el mejor crédito para tu negocio, con la tasa más baja del mercado. Aplican restricciones
                                    </h5>
                                    <br />
                                    <div className="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Requisitos:
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                        - Cédula de identidad y papeleta de votación.
                                    </h5>
                                    <h5 className="ps-4">
                                        - Mayor de 18 años.
                                    </h5>
                                    <h5 className="ps-4">
                                        - Justificación de ingresos (Roles de pago, RUC o RIMPE, declaraciones o facturas, certificado comercial).
                                    </h5>
                                    <h5 className="ps-4">
                                        - Planilla de servicio básico.
                                    </h5>
                                    <h5 className="ps-4">
                                        - Original de impuesto predial (en caso de tenerlo).
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Características del crédito:
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                        - AGILIDAD: Tu crédito se aprueba en un plazo de  24 a 48 horas.
                                    </h5>
                                    <h5 className="ps-4">
                                        - FLEXIBILIDAD: Si cancelas el 50% de tu microcrédito, accede a una novación de acuerdo a la necesidad de tu negocio o tu capacidad de pago.
                                    </h5>
                                    <h5 className="ps-4">
                                        - BENEFICIOS: Seguro médico para el deudor, cónyuge y 3 dependientes menos de 23 años, seguro de gravamen, también puedes acceder a nuestros servicios de odontología, peluquería y un fondo mortuorio de hasta $5.000.
                                    </h5>
                                    <h5 className="ps-4">
                                        - FACILIDAD: Las cuotas de tu microcrédito puedes cancelar desde cualquiera de nuestras Agencias a nivel nacional de la Coac. Futuro Lamanense o por transferencias electrónicas.
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Beneficios
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                        Si pagas tus cuotas puntuales, participas en los sorteos trimestrales
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br />
                            <SimuladorCredito codigotipocredito={'015'} tipocredito={'microcrédito'} />
                            <br />
                        </div>
                        <br />
                    </section>
                </main>
            </React.Fragment>
        )
    }
}

export default Microcredito;