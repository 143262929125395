import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../../styles/NoticeDetail.css';
import GaleriaComponent from '../../components/noticias/GaleriaComponent';
import EmptyResource from "../../../imagenes/noticias/svg/Empty-resource.svg";
import EmptyImageComponent from "../../components/noticias/EmptyImageComponent.jsx";
import ImageComponent from '../../components/noticias/ImageComponent.jsx';
import CardComponent from "../../components/noticias/CardComponent.jsx";
import { fetchApiNodeNoticies } from "../../../services/noticiasApi.js";
import urlGlobal from '../../../services/urlGlobal';
const { UrlGlobal, formatDateTime } = urlGlobal;

class NoticiaDetalle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listFirstThreeNotices: [],
      notice: null,
    };
  }

  async componentDidMount() {
    this.fetchData();
  }

  async componentDidUpdate(prevProps) {
    const { categId, noticeId } = this.props.match.params;
    const prevCategId = prevProps.match.params.categId;
    const prevNoticeId = prevProps.match.params.noticeId;

    if (categId !== prevCategId || noticeId !== prevNoticeId) {
      this.fetchData();
    }
  }

  fetchData = async () => {
    const { categId, noticeId } = this.props.match.params;
    try {
      const params = { id_noticie: noticeId, id_category: categId };
      const noticeData = await fetchApiNodeNoticies('POST', 'get-noticie-active', params);
      this.setState({ notice: noticeData.data[0] || null });
      console.log(noticeData.data[0]);

      const noticeFirstThreeData = await fetchApiNodeNoticies('POST', 'list-first-noticies', { id_category: categId, id_noticie: noticeId });
      this.setState({ listFirstThreeNotices: noticeFirstThreeData.data });
    } catch (error) {
      console.error('Error fetching noticeData:', error);
      this.setState({ notice: null, listFirstThreeNotices: [] });
    }
  };

  handleCardClick = () => {
    // Desplazar el scroll hacia el principio
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  render() {
    const { categName, categId, noticeId } = this.props.match.params;
    const { listFirstThreeNotices, notice } = this.state;

    return (
      <div className="container p-6" style={{ marginTop: 100 }}>
        <div className="position-relative">
          <Link to="/noticias" className="link">
            <i className="bi bi-arrow-left-short me-2 fs-2"></i>
            <span>Regresar</span>
          </Link>
          <div className="d-flex justify-content-center my-2">
            {notice?.img_banner ? (
              <ImageComponent urlImage={`${UrlGlobal.urlImagenesNoticias}${notice.img_banner}`}/>
            ) : (
              <EmptyImageComponent srcImage={EmptyResource} description={'No se tiene recursos'} />
            )}
          </div>
        </div>
        <div className="box">
          <h2>{notice?.title}</h2>
        </div>
        <div className="box">
          <div className="col-md-6 col-sm-12 my-2">
            <strong>Fecha: </strong>
            {notice?.date_time ? formatDateTime(notice.date_time) : ''}
          </div>
          <div className="col my-2">
            <span style={{ backgroundColor: "#77B735" }} className="badge rounded-pill fs-6">{categName}</span>
          </div>
        </div>
        {/* Contenido de la descripción */}
        <div className="box" dangerouslySetInnerHTML={{ __html: notice?.description }}>
        </div>
        {notice?.id_notice ? <GaleriaComponent id_notice={noticeId} /> : null}
        <div className="subtitle-notice-detail">
          <h2>También puede ser de su interés:</h2>
        </div>
        <div className="row d-flex justify-content-around align-items-stretch">
          {listFirstThreeNotices.length === 0 ? (
            <EmptyImageComponent srcImage={EmptyResource} description={'No se tiene recursos'} />
          ) : (
            listFirstThreeNotices.map((data) => (
              <CardComponent
                key={data.id_notice}
                id_notice={data.id_notice}
                idCateg={Number(categId)}
                title={data.title}
                img_card={data.img_card}
                date_time={data.date_time}
                description={data.description}
                nameCateg={categName}
                onClick={this.handleCardClick}
              />
            ))
          )}
        </div>
      </div>
    );
  }
}

export default NoticiaDetalle;
