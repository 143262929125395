import React from "react";
import { Link } from 'react-router-dom';

class Footer extends React.Component {
    render() {
        return (
            <React.Fragment>
                <footer id="footer">
                    <div className="footer-top">
                        <div className="container">
                            <div className="row">

                                <div className="col-lg-3 col-md-6 footer-contact">
                                    <h3>Futuro Lamanense</h3>
                                    <p>
                                        Cooperativa de ahorro y crédito<br /><br />
                                        <strong>Teléfono:</strong> (03) 2 568 435<br />
                                        <strong>Email:</strong> info@futurolamanense.fin.ec<br />
                                    </p>
                                </div>

                                <div className="col-lg-3 col-md-6 footer-links">
                                    <h4>Nuestros Créditos</h4>
                                    <ul>
                                        <li>
                                            <i className="bx bx-chevron-right"></i>
                                            <Link to="/credito-consumo" className="link-footer" >
                                                Crédito de consumo
                                            </Link>
                                        </li>
                                        <li>
                                            <i className="bx bx-chevron-right"></i>
                                            <Link to="/ahorro-inversion" className="link-footer" >
                                                Ahorro de Inversión
                                            </Link>
                                        </li>
                                        <li>
                                            <i className="bx bx-chevron-right"></i>
                                            <Link to="/ahorro-junior" className="link-footer" >
                                                Ahorro Junior
                                            </Link>
                                        </li>
                                        <li>
                                            <i className="bx bx-chevron-right"></i>
                                            <Link to="/ahorro-programado" className="link-footer" >
                                                Ahorro Programado
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <div className="col-lg-3 col-md-6 footer-links">
                                    <h4>Nuestros Servicios</h4>
                                    <ul>
                                        <li>
                                            <i className="bx bx-chevron-right"></i>
                                            <Link to="/peluqueria" className="link-footer" >
                                                Peluquería
                                            </Link>
                                        </li>
                                        <li>
                                            <i className="bx bx-chevron-right"></i>
                                            <Link to="/odontologia" className="link-footer" >
                                                Odontología
                                            </Link>
                                        </li>
                                        <li>
                                            <i className="bx bx-chevron-right"></i>
                                            <Link to="/fondo-mortuorio" className="link-footer" >
                                                Fondo Mortuorio
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <div className="col-lg-3 col-md-6 footer-links">
                                    <h4>Visítanos en nuestras redes sociales</h4>
                                    <p>No te pierdas de estar al tanto de nuestras publicaciones</p>
                                    <div className="social-links mt-3">
                                        <a href="https://twitter.com/FLM_EC" target="_blank" className="twitter"><i className="bx bxl-twitter"></i></a>
                                        <a href="https://www.facebook.com/COACFUTLAMANENSE" target="_blank" className="facebook"><i className="bx bxl-facebook"></i></a>
                                        <a href="https://www.instagram.com/futuro_lamanense/" target="_blank" className="instagram"><i className="bx bxl-instagram"></i></a>
                                        <a href="https://www.youtube.com/channel/UCWy9n8Y23_QQern3sZVX7oA" target="_blank" className="google-plus"><i className="bx bxl-youtube"></i></a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="container footer-bottom clearfix">
                        <div className="copyright">
                            &copy; Copyright <strong><span>Futuro Lamanense</span></strong>. All Rights Reserved
                        </div>
                        <div className="credits">
                            Desarrollado por Departamento de TI
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}

export default Footer;